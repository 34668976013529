import React, { useEffect, useState } from "react";
import BreadCrumb from "../../components/BreadCrumb";
import CollectionImg from "../../assets/col.png";
import { Link } from "react-router-dom";
import ApiService from "../../api/axios";
import Meta from "../../components/Meta/Meta";
import { _t } from "../../helpers/helpers";

const Collection = () => {
  const [create, setCreate] = useState({
    media: [],
  });
  const [pageMeta, setPageMeta] = useState({});
  const [breadcrumbs, setBreadcrumbs] = useState([]);

  useEffect(() => {
    ApiService.get("/pageByUrl?url=create-your-perfume").then((resp) => {
      setCreate(resp.data.data);
      setPageMeta(resp.data.page_meta);
      setBreadcrumbs(resp.data.page_meta.breadcrumbs);
    });
  }, []);

  return (
    <div className="collection">
      <Meta meta={pageMeta} />
      <div className="container">
        <BreadCrumb breadcrumbs={breadcrumbs} />
        <div className="collectionHeader collectionHeaderCreate">
          <h4 className="title">{create.title}</h4>
          <p>{create.description}</p>
          <iframe
            src={create.video_link}
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
          <div
            className="createP"
            dangerouslySetInnerHTML={{
              __html: create.content,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Collection;
